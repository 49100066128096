import React, { useEffect, useState } from "react";
// import styles from "./Layout.module.css";
import { styles } from "./layout.styles";
import BackIcon from "./assets/icons/BackIcon.svg";
import ForwardIcon from "./assets/icons/ForwardIcon.svg";
import { useQuiz } from "./contexts/QuizContext";
import { Box, Button, IconButton, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { BackButton, NextButton } from "./components/NavButton";
import { BreadcrumButton } from "./components/BreadcrumbButton";

const Layout = ({ children }) => {
    const questionList = [1, 2, 3, 4, 5, 6, 7];
    const domain = "schmerz-spezialisten.de";
    const questionTitles = [
        "Welche Aussage beschreibt Ihre Situation am besten?",
        "Wie lange leiden Sie oder ein Angehöriger bereits an chronischen Schmerzen?",
        "Wie stark sind Ihre Schmerzen im Durchschnitt?",
        "Wo befinden sich Ihre Schmerzen?",
        "Empfinden Sie Erleichterung wenn Sie sich aufstützen, sich nach vorne lehnen, sich setzen?",
        "Wählen Sie alle bisher oder aktuell  genutzten Behandlungsoptionen aus.",
        "Befinden Sie sich zurzeit bei einem Schmerzspezialisten in Behandlung?",
    ];
    const {
        currentQuestion,
        setCurrentQuestion,
        questionOne,
        questionTwo,
        questionThree,
        questionFour,
        questionFive,
        questionSix,
        questionSeven,
        isMobile,
        setAnsweredQuestions,
        answeredQuestions,
        setQuizCompleted,
    } = useQuiz();
    const [labels, setLabels] = useState(["Zurück", "Weiter"]);
    const highestAnsweredQuestion =
        answeredQuestions[answeredQuestions.length - 1];

    const getAnswer = () => {
        if (currentQuestion === 1) return questionOne;
        if (currentQuestion === 2) return questionTwo;
        if (currentQuestion === 3) return questionThree;
        if (currentQuestion === 4) return questionFour;
        if (currentQuestion === 5) return questionFive;
        if (currentQuestion === 6) return questionSix;
        if (currentQuestion === 7) return questionSeven;
    };

    const next = () => {
        if (currentQuestion > 0 && currentQuestion < 8) {
            window.dataLayer.push({
                event: "interactionEvent",
                eventAction: "quiz event | question answered",
                eventCategory: `${domain} | quiz pages`,
                eventLabel: currentQuestion,
                CD67: "pain questionnaire",
                CD68: getAnswer().toString(),
                CD71: questionTitles[currentQuestion - 1],
                CD72: currentQuestion,
            });

            console.log("quiz event | question answered =>", window.dataLayer);
        }
        if (currentQuestion > 0 && currentQuestion === 7) {
            window.dataLayer.push({
                event: "interactionEvent",
                eventAction: "quiz event | complete",
                eventCategory: `${domain} | quiz pages`,
                eventLabel: currentQuestion,
                CD67: "pain questionnaire",
                // CD70: "Aufgrund Ihrer Antworten könnten Sie ein Kandidat / eine Kandidatin für eine Rückenmarkstimulation (SCS) oder Radiofrequenzablation (RFA) sein, aber nur ein Arzt / eine Ärztin kann diese Entscheidung treffen. *",
                CD72: "complete",
            });

            console.log("quiz event | quiz complete =>", window.dataLayer);
        }
        if (currentQuestion === 1 && questionOne.length > 0) {
            setCurrentQuestion(2);
            setAnsweredQuestions((prev) =>
                prev.includes(1) ? prev : [...prev, 1],
            );
            return;
        }
        if (currentQuestion === 2 && questionTwo === "0-5 Monate") {
            setCurrentQuestion(-1);
            setAnsweredQuestions((prev) =>
                prev.includes(2) ? prev : [...prev, 2],
            );
            return;
        }
        if (currentQuestion === 2 && questionTwo.length > 0) {
            setCurrentQuestion(3);
            setAnsweredQuestions((prev) =>
                prev.includes(2) ? prev : [...prev, 2],
            );
            return;
        }
        if (currentQuestion === 3) {
            setCurrentQuestion(4);
            setAnsweredQuestions((prev) =>
                prev.includes(3) ? prev : [...prev, 3],
            );
            return;
        }
        if (currentQuestion === 4 && questionFour.length > 0) {
            setCurrentQuestion(5);
            setAnsweredQuestions((prev) =>
                prev.includes(4) ? prev : [...prev, 4],
            );
            return;
        }
        if (currentQuestion === 5 && questionFive !== undefined) {
            setCurrentQuestion(6);
            setAnsweredQuestions((prev) =>
                prev.includes(5) ? prev : [...prev, 5],
            );
            return;
        }
        if (currentQuestion === 6 && questionSix.length > 0) {
            setCurrentQuestion(7);
            setAnsweredQuestions((prev) =>
                prev.includes(6) ? prev : [...prev, 6],
            );
            return;
        }
        if (currentQuestion === 7 && questionSeven !== undefined) {
            // setCurrentQuestion(-2);
            setQuizCompleted(true);
            setCurrentQuestion(null);
            setAnsweredQuestions((prev) =>
                prev.includes(7) ? prev : [...prev, 7],
            );
            return;
        }
        // if (currentQuestion === -2) {
        //     setQuizCompleted(true);
        //     return;
        // }

        alert("Bitte beantworten Sie die Frage, um fortzufahren");
    };
    const back = () => {
        if (currentQuestion === -1) setCurrentQuestion(1);
        if (currentQuestion === -2) setCurrentQuestion(7);
        if (currentQuestion > 1) {
            setCurrentQuestion((prev) => prev - 1);
        }
    };

    const advanceViaBreamdcrumbs = (number) => {
        if (number <= highestAnsweredQuestion) {
            setCurrentQuestion(number);
        }
    };

    return (
        <Box
            sx={{
                ...styles.root,
                justifyContent: "flex-start",
            }}
        >
            <Box
                sx={{
                    ...styles.navContainer,
                    justifyContent: isMobile ? "center" : "space-between",
                    marginInline: { md: "1rem" },
                    width: { lg: "80vw" },
                }}
            >
                <BackButton
                    label={labels[0]}
                    handleBack={back}
                    isVisible={currentQuestion !== 1 && currentQuestion > 0}
                />
                <Box sx={{ marginInline: "1rem" }}>{children}</Box>
                <NextButton
                    label={
                        currentQuestion === 7 ? "Ergebnis anfordern" : labels[1]
                    }
                    handleNext={next}
                    // allowAdvance={allowAdvance}
                    isVisible={currentQuestion !== 10 && currentQuestion > 0}
                />
            </Box>
            {currentQuestion !== 10 && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: "1rem",
                        left: { xs: 0, sm: "20vw", xl: "25vw" },
                    }}
                >
                    <Box sx={styles.footer}>
                        {(isMobile || currentQuestion === -2) &&
                            currentQuestion !== -1 && (
                                <Box sx={styles.footerRow}>
                                    <Button
                                        sx={styles.navButtonResult}
                                        onClick={back}
                                    >
                                        {currentQuestion === -2
                                            ? "Zurück zum Quiz"
                                            : "Zurück"}
                                    </Button>
                                    <Button
                                        sx={styles.navButtonResult}
                                        onClick={next}
                                    >
                                        {currentQuestion === 7
                                            ? "Ergebnis anfordern"
                                            : "Weiter"}
                                    </Button>
                                </Box>
                            )}
                        <Box sx={styles.footerRow}>
                            {questionList.map((number) => {
                                return (
                                    <BreadcrumButton
                                        number={number}
                                        advanceViaBreamdcrumbs={
                                            advanceViaBreamdcrumbs
                                        }
                                        isActive={number === currentQuestion}
                                        isDisabled={
                                            highestAnsweredQuestion < number
                                        }
                                        isCompleted={
                                            highestAnsweredQuestion >= number
                                        }
                                    />
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Layout;
